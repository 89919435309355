// ==============================|| AUTH ROUTING ||============================== //

import RegisterForm from "../components/authForms/RegisterForm";

const RegisterRoutes = {
  path: "/registerAdminUser",
  element: <RegisterForm />,
};

export default RegisterRoutes;
