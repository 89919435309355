// ==============================|| AUTH HOOKS ||============================== //
import axios, * as others from "axios";
const API_URL = process.env.REACT_APP_BUBBA_URL;


const isLoggedIn = () => {
  const isLoggedIn = !!localStorage.getItem("authToken");
  return isLoggedIn;
};

const logout = () => {
  localStorage.clear();
  window.location.replace("/");
};

const login = async (email: string, password: string) => {
  try {
    const loginResponse = await axios.post(
      `${API_URL}/api/v1/login`,
      {
        email,
        password,
      }
    );
    localStorage.setItem("authToken", loginResponse.data.body.token);
  } catch (err) {
    throw err;
  }
};

const register = async (email: string, password: string) => {
  try {
    const registerResponse = await axios.post(
      `${API_URL}/api/v1/user`,
      {
        email,
        password,
      }
    );
  } catch (err) {
    throw err;
  }
};

const useAuth = () => {
  const context = {
    logout,
    isLoggedIn,
    login,
    register
  };

  if (!context) throw new Error("context must be use inside provider");

  return context;
};

export default useAuth;
