// ==============================|| AUTH ROUTING ||============================== //

import ClientPage from "../components/client";

const ClientRoutes = {
  path: "/authentication/:id",
  element: <ClientPage />,
};

export default ClientRoutes;
