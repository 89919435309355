import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  useMediaQuery,
  Grid,
  Box,
  Container,
  Typography,
  Link,
  AppBar,
  Toolbar,
  colors,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { padding } from "@mui/system";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  faCheckCircle,
  faXmarkCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import useQR from "../../hooks/useQR";
import { QR } from "../../types";
const ClientPage = () => {
  const [qrCheck, setQrCheck] = React.useState<QR>();
  const [errCheck, setErrCheck] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { getOneQr, updateStatus, updateCount } = useQR();
  const { id } = useParams();

  const updateContract = {
    id: qrCheck?.id,
    status: false,
    clientInfo: qrCheck?.clientInfo,
    strain: qrCheck?.strain,
    count: qrCheck?.count,
  } as unknown as QR;

  const updateContractCount = {
    id: qrCheck?.id,
    status: qrCheck?.status,
    clientInfo: qrCheck?.clientInfo,
    strain: qrCheck?.strain,
    count: qrCheck?.count,
  } as unknown as QR;

  React.useEffect(() => {
    if (id) {
      try {
        getOneQr(id).then(
          (qrInfo: QR) => {
            setQrCheck(qrInfo);
            updateStatus(updateContract, id);
            setLoading(true);
          },
          (err: any) => {
            if (err) {
              setErrCheck(true);
              setLoading(true);
            }
          }
        );
      } catch (err: any) {
        if (err) {
          setErrCheck(true);
          setLoading(true);
        }
      }
    }
  }, []);

  if (id && qrCheck) {
    updateCount(updateContractCount, id);
  }

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 3,
          paddingLeft: 10,
          paddingRight: 10,
          backgroundColor: "#222020",
          height: "100%",
          paddingBottom: 10,
        }}
      >
        <Button
          sx={{ paddingBottom: 3 }}
          onClick={() => {
            window.open("https://highestone.co/menu");
          }}
        >
          <img
            src={require("../../images/bubbaTransparent.png")}
            style={{ width: 170 }}
          />
        </Button>
        {(() => {
          if (qrCheck?.status) {
            return (
              <Typography
                variant="overline"
                color={"white"}
                fontSize={15}
                gutterBottom
                align="center"
              >
                Verification Complete
              </Typography>
            );
          } else if (qrCheck && !qrCheck?.status) {
            return (
              <Typography
                variant="overline"
                color={"white"}
                fontSize={13}
                gutterBottom
                align="center"
              >
                This product has already been verified
              </Typography>
            );
          } else if (errCheck) {
            return (
              <Typography
                variant="overline"
                color={"white"}
                fontSize={15}
                gutterBottom
                align="center"
              >
                Try Again or contact us
              </Typography>
            );
          } else {
            <Typography
              variant="overline"
              color={"white"}
              fontSize={15}
              gutterBottom
              align="center"
            >
              Product Verification
            </Typography>;
          }
        })()}
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            color: "white",
            marginTop: 5,
          }}
          spacing={2}
        >
          {loading ? (
            (() => {
              if (qrCheck?.status) {
                return (
                  <>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "#48ca48", fontSize: 150 }}
                    />
                    <Typography color={"#E4C488"}>{qrCheck.strain}</Typography>
                    <Typography color={"#E4C488"}>
                      Packed {moment(qrCheck.createdAt).format("MMM YYYY"!)}
                    </Typography>
                  </>
                );
              } else if (qrCheck && !qrCheck?.status) {
                return (
                  <>
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      style={{ color: "orange", fontSize: 150 }}
                    />
                    <Typography color={"#E4C488"}>{qrCheck.strain}</Typography>
                    <Typography color={"#E4C488"}>
                      Packed {moment(qrCheck.createdAt).format("MMM YYYY"!)}
                    </Typography>
                  </>
                );
              } else if (errCheck) {
                return (
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    style={{ color: "red", fontSize: 150 }}
                  />
                );
              }
            })()
          ) : (
            <CircularProgress
              size={150}
              sx={{ color: "white", marginBottom: 20 }}
            />
          )}
        </Stack>

        <Button
          sx={{
            marginTop: 9,
          }}
          onClick={() => {
            window.open("mailto:bubbakingsbc@protonmail.com");
          }}
        >
          <Typography
            variant="overline"
            color={"white"}
            fontSize={15}
            gutterBottom
          >
            Click Here To Email Us
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default ClientPage;
