import React from "react";
import { useParams } from "react-router-dom";
import { QR } from "../../types";
import useQR from "../../hooks/useQR";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";

const QRGenerator = require("qrcode");

const PrintScreen = () => {
  const [qrLink, setQrLink] = React.useState("");
  const { id } = useParams();
  const [qrInfo, setQrInfo] = React.useState<QR>();
  const { getOneQr } = useQR();

  const qrMaker = async (id: string) => {
    try {
      const qr = await QRGenerator.toDataURL(
        `http://bubbakings.ca/authentication/${id}`
      );
      if (qr) {
        setQrLink(qr);
      }
    } catch (err) {}
  };

  React.useEffect(() => {
    if (id) {
      qrMaker(id);
    }
  }, []);

  React.useEffect(() => {
    if (id) {
      getOneQr(id).then((qrInfo: QR) => {
        setQrInfo(qrInfo);
      });
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 0,
          }}
        >
          <Typography
            sx={{
              writingMode: "vertical-rl",
              textOrientation: "upright",
              fontSize: 35,
            }}
          >
            BUBBA
          </Typography>
        </Box>

        <img src={qrLink} style={{ width: 300, padding: 0 }} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 0,
          }}
        >
          <Typography
            sx={{
              writingMode: "vertical-rl",
              textOrientation: "upright",
              fontSize: 35,
            }}
          >
            KINGS
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 0,
        }}
      >
        <Typography fontSize={25}>{qrInfo?.strain}</Typography>
        <Typography fontSize={25}>
          Packed: {moment(qrInfo?.createdAt).format("MMM YYYY"!)}
        </Typography>
      </Box>
    </>
  );
};

export default PrintScreen;
