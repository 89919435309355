import { useRoutes } from "react-router-dom";
import LoginForm from "../components/authForms/LoginForm";
import ClientRoutes from "./ClientRoutes";
import AdminRoutes from "./AdminRoutes";
import RegisterRoutes from "./RegisterRoutes";
import PrintRoutes from "./PrintRoutes";

export default function Routes() {
  return useRoutes([
    {
      path: "/",
      element: <LoginForm />,
    },
    ClientRoutes,
    AdminRoutes,
    RegisterRoutes,
    PrintRoutes,
  ]);
}
