import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  useMediaQuery,
  Grid,
  Box,
  Container,
  Typography,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { padding } from "@mui/system";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const LoginForm = () => {
  const theme = createTheme();
  const navigate = useNavigate();
  const { login } = useAuth();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        await login(values.email, values.password).then(
          () => {
            window.location.replace("/admin");
          },
          (err: any) => {
            if (err) {
              setStatus({ success: false });
              setErrors({ submit: "Wrong Password" });
              setSubmitting(false);
            }
          }
        );
      } catch (err: any) {
        console.error(err);
        if (err) {
          setStatus({ success: false });
          setErrors({ submit: "Wrong Password" });
          setSubmitting(false);
        }
      }
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md" sx={{}}>
          <CssBaseline />

          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                boxShadow:
                  "0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)",
                borderRadius: 2,
                padding: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <img
                src={require("../../images/bubbaTransparent.png")}
                style={{ width: 350, paddingBottom: 50 }}
              />

              <FormControl sx={{ padding: 2, width: 430 }}>
                <TextField
                  label="Email"
                  id="email"
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  variant="outlined"
                />

                {formik.touched.email && formik.errors.email ? (
                  <FormHelperText error>{formik.errors.email}</FormHelperText>
                ) : null}
              </FormControl>

              <FormControl sx={{ paddingBottom: 3, width: 400 }}>
                <TextField
                  label="Password"
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  autoComplete="current-password"
                  variant="outlined"
                />

                {formik.touched.password && formik.errors.password ? (
                  <FormHelperText error>
                    {formik.errors.password}
                  </FormHelperText>
                ) : null}
              </FormControl>

              {formik.errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Box>
              )}

              <Button type="submit" variant="outlined" sx={{ width: 200 }}>
                Login
              </Button>
            </Box>
          </form>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default LoginForm;
