// ==============================|| AUTH HOOKS ||============================== //
import axios, * as others from "axios";
import { QR } from "../types";

const API_URL = process.env.REACT_APP_BUBBA_URL;

const createQr = async (clientInfo: string | null, strain: string, count: number, status: boolean,) => {
  const authToken = localStorage.getItem('authToken')

  try {
    const qrResponse = await axios.post(`${API_URL}/api/v1/qrcode`, {
   status: status,
  clientInfo: clientInfo,
  strain: strain,
  count: count
    },{
      headers: {'Authorization': `bearer ${authToken}`}  

  });

  } catch (err) {
    throw err;
  }
};

function getQrs(): Promise<QR[]> {
  const authToken = localStorage.getItem('authToken')
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/qrcode`,{
        headers: {'Authorization': `bearer ${authToken}`}  

    });
  
      resolve(response.data.body);
    } catch (error) {
      reject(error);
    }
  });
}

function getOneQr(id: string): Promise<QR> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/qrcodeOne/${id}`);
      resolve(response.data.body);
    } catch (error) {
      reject(error);
    }
  });
}

const updateStatus = async (qrContract: QR, id: string) => {
  try {
    const response = await axios.put(`${API_URL}/api/v1/qrcode/${id}`, {
      ...qrContract,
    });
  } catch (error) {}
};
const updateStatusAdmin = async (qrContract: QR, id: string) => {
  try {
    const response = await axios.put(`${API_URL}/api/v1/qrcode/${id}`, {
      ...qrContract,
    });
    window.location.replace("/admin");

  } catch (error) {}
};

const updateCount = async (qrContract: QR, id: string) => {
  try {
    const response = await axios.put(`${API_URL}/api/v1/qrcode/count/${id}`, {
      ...qrContract,
    });
  } catch (error) {}
};

const deleteQr = async (id: string) => {
  try {
    const response = await axios.delete(`${API_URL}/api/v1/qrcode/${id}`, );
    window.location.replace("/admin");
  } catch (error) {}
};


const useQR = () => {
  const context = {
    createQr,
    getQrs,
    getOneQr,
    updateStatus,
    updateCount,
    deleteQr,
    updateStatusAdmin 
  };

  if (!context) throw new Error("context must be use inside provider");

  return context;
};

export default useQR;
