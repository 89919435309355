import PrintScreen from "../components/admin/PrintScreen";
import AuthGuard from "../guards/authGuard";

const PrintRoutes = {
  path: "/print/:id",
  element: (
    <AuthGuard>
      <PrintScreen />
    </AuthGuard>
  ),
};

export default PrintRoutes;
