import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Modal,
  Toolbar,
  Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import CreateQRModal from "./CreateQRModal";
import { QR } from "../../types";
import useQR from "../../hooks/useQR";
import useAuth from "../../hooks/useAuth";

export default function QRList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [listQRs, setQRs] = React.useState<QR[]>([]);
  const { logout } = useAuth();

  const { getQrs, updateStatusAdmin, deleteQr } = useQR();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [qrLink, setQrLink] = React.useState("");

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const navigate = useNavigate();
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",

    pt: 4,
  };

  React.useEffect(() => {
    getQrs().then((qrs: QR[]) => {
      setQRs(qrs);
    });
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: "#222020" }}>
          <Toolbar>
            <img
              src={require("../../images/bubbaTransparent.png")}
              style={{ width: 80, padding: 20 }}
            />
            <Typography variant="inherit" component="div" sx={{ flexGrow: 1 }}>
              QR Code Manager
            </Typography>
            <Button
              onClick={() => {
                logout();
              }}
              color="inherit"
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        padding={3}
        spacing={2}
        paddingTop={9}
      >
        <Button onClick={handleOpen}>
          <FontAwesomeIcon style={{ fontSize: 24 }} icon={faPlus} />
        </Button>
      </Grid>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CreateQRModal />
        </Modal>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, marginTop: 4 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 15 }}>
                  ID
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 15 }}>
                  Client Info
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 15 }}>
                  Date Created
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 15 }}>
                  Scans
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontSize: 15 }}
                  align="right"
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontSize: 15 }}
                  align="right"
                >
                  Print
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontSize: 15 }}
                  align="right"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listQRs
                .slice(0)
                .reverse()
                .map((row: any) => (
                  <>
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "& .MuiTableRow-root:hover": {
                          backgroundColor: "primary.light",
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.clientInfo}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {moment(row.createdAt).format("LL"!)}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.count}
                      </TableCell>

                      <TableCell align="right">
                        {row.status === true ? (
                          <Chip
                            clickable
                            label="Active"
                            color="success"
                            onClick={() => {
                              const updateContract = {
                                id: row.id,
                                status: false,
                                clientInfo: row.clientInfo,
                                strain: row.strian,
                                packageDate: row.packageDate,
                                count: row.count,
                              } as unknown as QR;

                              updateStatusAdmin(updateContract, row.id);
                            }}
                          />
                        ) : (
                          <Chip
                            clickable
                            label="Inactive"
                            color="error"
                            onClick={() => {
                              const updateContract1 = {
                                id: row.id,
                                status: true,
                                clientInfo: row.clientInfo,
                                strain: row.strian,
                                packageDate: row.packageDate,
                                count: row.count,
                              } as unknown as QR;

                              updateStatusAdmin(updateContract1, row.id);
                            }}
                          />
                        )}
                      </TableCell>

                      <TableCell align="right">
                        <Link to={`/print/${row.id}`} target="_blank">
                          <Button>Print</Button>
                        </Link>
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          onClick={() => {
                            deleteQr(row.id);
                          }}
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: 17, color: "red" }}
                            icon={faTrashCan}
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContainer>
    </>
  );
}
