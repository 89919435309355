import AdminPage from "../components/admin";
import AuthGuard from "../guards/authGuard";

const AdminRoutes = {
  path: "/admin",
  element: (
    <AuthGuard>
      <AdminPage />
    </AuthGuard>
  ),
};

export default AdminRoutes;
