import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormHelperText,
  TextField,
  useMediaQuery,
  Box,
  Autocomplete,
  Grid,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useQR from "../../hooks/useQR";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CreateQRModal = () => {
  const { createQr } = useQR();
  const navigate = useNavigate();
  const [strain, setStrain] = useState("");

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      clientInfo: "",
      strain: "",
      count: 0,
      status: true,
      submit: null,
    },
    validationSchema: Yup.object({}),

    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        await createQr(
          values.clientInfo,
          values.strain,
          values.count,
          values.status
        ).then(
          () => {
            window.location.replace("/admin");
          },
          (err: any) => {
            if (err) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        );
      } catch (err: any) {
        console.error(err);
        if (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }
    },
  });

  const handleType = (strain: any) => {
    formik.values.strain = strain?.label!;
    setStrain(strain.label);
  };

  return (
    <Box sx={style}>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: 2,
        }}
      >
        <Typography sx={{ fontSize: 20 }}>Create QR</Typography>
      </Grid>

      <form onSubmit={formik.handleSubmit}>
        <FormControl sx={{ paddingBottom: 3 }} fullWidth>
          <TextField
            label="Client Info"
            id="clientInfo"
            name="clientInfo"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.clientInfo}
            variant="outlined"
          />
          {formik.touched.clientInfo && formik.errors.clientInfo ? (
            <FormHelperText error>{formik.errors.clientInfo}</FormHelperText>
          ) : null}
        </FormControl>

        <FormControl sx={{ paddingBottom: 3 }} fullWidth>
          <Autocomplete
            disablePortal
            id="strain"
            options={strains}
            renderInput={(params) => <TextField {...params} label="Strain" />}
            onChange={(a, b) => handleType(b)}
          />
        </FormControl>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 2,
          }}
        >
          {formik.errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{formik.errors.submit}</FormHelperText>
            </Box>
          )}
          <Button type="submit" variant="outlined" sx={{ width: 200 }}>
            Create
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default CreateQRModal;

const strains = [
  { label: "Mike Tyson" },
  { label: "Death Cookies" },
  { label: "Lizard" },
  { label: "Pre-98" },
  { label: "Chapo" },
  { label: "Jeffe" },
  { label: "El Muerte" },
  { label: "Death Bubba" },
  { label: "MKU" },
  { label: "Flight 23" },
];
